.main-content {
    min-height: 500px;
}

.cookie {
    display: none;
    background-color: #f3f3f3;
    position: relative;
    padding: 8px 60px 8px 10px;
    p {
        line-height: 24px;
        margin: 0;
    }
    .cookie-close {
        display: block;
        position: absolute;
        right: 10px;
        top: 7px;
        width: 26px;
        height: 26px;
        background: url(../img/cookie_close.png) no-repeat;
        cursor: pointer;
    }
}

.home .banner-strip .container h1 {
    font-size: 40px;
    line-height: 50px;
}
.menu-container {
    float: right;
    height: 107px;
}
.navs {
    height: 107px;
    ul {
        height: 53px;
        width: 100%;
        margin: 54px 0 0 0;
        li {
            height: 53px;
        }
        a {
            display: block;
            height: 19px;
        }
    }
}
.logo {
    margin-left: 0px;
    a {
        background: url("../img/cabotie/logo-ie.jpg") center no-repeat scroll;
        background-size: auto;
        display: block;
        height: 107px;
        line-height: 94px;
        overflow: hidden;
        text-indent: 540px;
        width: 230px;
    }
}

.input-large, .input-xlarge, .input-xxlarge, input[class*="span"], select[class*="span"], textarea[class*="span"], .uneditable-input {

}

.formitem {
    .span5 {
        .dob_day {
            width: 90px;
        }
        .dob_month {
            width: 180px;
        }
        .dob_year {
            width: 100px;
        }
    }
}

.currency_option {
    select {
        margin-left: 20px;
    }
}

footer #nav-container nav {
    width: 840px;
}

header {
	.account-controls {
	    position: absolute;
	    right: 0;
	    top: 0;
	}
}

.pemail {
    word-wrap: break-word;
    font-size: 17px;
}
.super-phone a {
    cursor: default;
    text-decoration: none;
    &:hover {
        color: #d20629;
    }
}

.ccmquote {
    text-align: center;
    font-style: italic;

}

select.span5 {
    width: 380px;
}
